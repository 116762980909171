@import "~antd/dist/antd.css";

.ant-layout-sider,
.ant-layout-header {
  background-color: #f0f2f5;
}

.ant-layout-sider {
  height: 100vh;
}

div.ant-collapse-item.panelStatus0 {
  background-color: turquoise;
}

div.ant-collapse-item.panelStatus1 {
  color: yellowgreen;
  margin-right: 5px;
}
div.ant-collapse-item.panelStatus1:nth-child(even) {
  color: #89bc21;
  margin-right: 5px;
}
div.ant-collapse-item.panelStatus-1 {
  color: #FF6347FF;
  margin-right: 5px;
}
div.ant-collapse-item.panel-item:nth-child(even) {
 background-color: #f0f2f3;
}

.site-page-header {
  border: 1px solid rgb(235, 237, 240);
  text-transform: capitalize;
  background-color: #fff;
  border-bottom: none;
  padding-top: 10px;
  padding-bottom: 10px;
}
.sceenic-logo {
  width: 200px;
  height: 50px;
  margin: 10px 25px;
  background-position: center;
  background-size: cover;
  background-image: url("../public/sceenic.ico");
  cursor: pointer;
}
.ant-card-head {
  border-bottom: none
}
.status-card {
  text-transform: capitalize;
  margin-right: 5px;
  margin-bottom: 5px;
}
.small-status-card {
  width: 32%
}
.middle-status-card {
  width: 49%
}
.large-status-card {
  width: 100%;
}

.progress-total {
 cursor: pointer
}
.status-dashboard-container {
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content: center
}
.status-dashboard-container .ant-card-head-wrapper .ant-card-head-title{
  padding-top: 3px;
  padding-bottom: 3px;
}
